<template>
  <div class="container-fluid">
    <pagamento />
  </div>
</template>

<script>
import Pagamento from "@/components/pagamento/Pagamento.vue";

export default {
  name: "Finalizacao",
  components: {
    Pagamento,
  },
};
</script>
