<template>
  <div class="card my-2 p-2">
    <img
      src="img/pix.png"
      alt="Logo do Pix"
      class="card-img-top mx-auto d-block logo"
    />
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-100">
          <strong class="card-title text-info">03.927.482/0001-18</strong>
        </div>
        <button
          v-clipboard="'03927482000118'"
          type="button"
          class="btn btn-outline-info btn-sm"
          title="Copiar"
        >
          <font-awesome-icon icon="copy" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  width: 100vw;
  max-width: 100px;
}
</style>