<template>
  <div class="card my-2 p-2">
    <img
      src="img/sicoob.png"
      alt="Logo do Sicoob"
      class="card-img-top mx-auto d-block mt-2 logo"
    />
    <div class="card-body">
      <div class="card-title text-sicoob">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item p-1 d-flex justify-content-between align-items-center"
          >
            <div class="w-100">
              <small class="d-block font-weight-bold">Banco</small>
              <span>Sicoob</span>
            </div>
            <button
              v-clipboard="'Sicoob'"
              type="button"
              class="btn btn-outline-info btn-sm"
              title="Copiar"
            >
              <font-awesome-icon icon="copy" />
            </button>
          </li>
          <li
            class="list-group-item p-1 d-flex justify-content-between align-items-center"
          >
            <div class="w-100">
              <small class="d-block font-weight-bold">Agência</small>
              <span>3010</span>
            </div>
            <button
              v-clipboard="'3010'"
              type="button"
              class="btn btn-outline-info btn-sm"
              title="Copiar"
            >
              <font-awesome-icon icon="copy" />
            </button>
          </li>
          <li
            class="list-group-item p-1 d-flex justify-content-between align-items-center"
          >
            <div class="w-100">
              <small class="d-block font-weight-bold">Conta</small>
              <span>109.159-0</span>
            </div>
            <button
              v-clipboard="'1091590'"
              type="button"
              class="btn btn-outline-info btn-sm"
              title="Copiar"
            >
              <font-awesome-icon icon="copy" />
            </button>
          </li>
          <li
            class="list-group-item p-1 d-flex justify-content-between align-items-center"
          >
            <div class="w-100">
              <small class="d-block font-weight-bold">CNPJ</small>
              <span class="text-wrap">03.927.482/0001-18</span>
            </div>
            <button
              v-clipboard="'03927482000118'"
              type="button"
              class="btn btn-outline-info btn-sm"
              title="Copiar"
            >
              <font-awesome-icon icon="copy" />
            </button>
          </li>
          <li
            class="list-group-item p-1 d-flex justify-content-between align-items-center"
          >
            <div class="w-100">
              <small class="d-block font-weight-bold">Razão Social</small>
              <span class="text-wrap"
                >GMR Distribuição de Prod. Alimentícios</span
              >
            </div>
            <button
              v-clipboard="'GMR Distribuição de Prod. Alimentícios'"
              type="button"
              class="btn btn-outline-info btn-sm"
              title="Copiar"
            >
              <font-awesome-icon icon="copy" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  width: 100vw;
  max-width: 100px;
}

.text-sicoob {
  color: #003d44;
}
</style>