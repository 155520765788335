<template>
  <div>
    <transition mode="out-in" name="fade">
      <picpay v-if="showPicpay" :valor="pedido.valor" />
    </transition>
    <transition mode="out-in" name="fade">
      <pix v-if="showPix" />
    </transition>
    <transition mode="out-in" name="fade">
      <transferencia-bancaria v-if="showTranferencia" />
    </transition>

    <div class="card my-2 p-2" v-if="showResumoPedido">
      <div class="card-body">
        <div class="card-title lead font-weight-bold">Resumo do Pedido</div>
        <div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item p-1 d-flex justify-content-between align-items-center"
            >
              <div class="w-100">
                <small class="d-block font-weight-bold">Realização</small>
                <span>{{ pedido.realizacao | data }}</span>
              </div>
            </li>

            <li
              class="list-group-item p-1 d-flex justify-content-between align-items-center"
            >
              <div class="w-100">
                <small class="d-block font-weight-bold">Número</small>
                <span>{{ pedido.numero }}</span>
              </div>
            </li>

            <li
              class="list-group-item p-1 d-flex justify-content-between align-items-center"
            >
              <div class="w-100">
                <small class="d-block font-weight-bold">Valor</small>
                <span>{{ pedido.valor | dinheiro }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-warning btn-block rounded-0 voltar"
      title="Voltar para a Página Inicial"
    >
      Voltar para a Página Inicial
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TransferenciaBancaria from "./components/TransferenciaBancaria.vue";
import Pix from "./components/Pix.vue";
import Picpay from "./components/Picpay.vue";

export default {
  components: {
    TransferenciaBancaria,
    Pix,
    Picpay,
  },
  computed: {
    ...mapGetters({
      pedido: "getPedidoFinalizado",
    }),
    pagamentoPedido() {
      return this.pedido && this.pedido.pagamento
        ? this.pedido.pagamento
        : false;
    },
    showTranferencia() {
      return this.pagamentoPedido === "TRANSFERENCIA" || this.rotaPagamento;
    },
    showPix() {
      return this.pagamentoPedido === "PIX" || this.rotaPagamento;
    },
    showPicpay() {
      return this.pagamentoPedido === "PICPAY" || this.rotaPagamento;
    },
    showResumoPedido() {
      return this.pedido.numero && !this.rotaPagamento;
    },
    rotaPagamento() {
      return this.$route.path === "/pagamento";
    },
  },
};
</script>

<style>
</style>